





























import Vue from "vue";
import _ from 'lodash';
import { ulid } from 'ulid';
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component('Display', {
  components: {
    QLoader: () => import('../../components/elements/QLoader.vue'),
    QButton: () => import("../../components/elements/QButton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
      isLoading: false,
      errors: "",
    };
  },
  mounted() {
    api.set_token(store.state.token);
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    total() {
      return store.state.customerDisplay.total;
    },
    cash() {
      return store.state.customerDisplay.cash;
    },
    change() {
      return store.state.customerDisplay.change;
    },
  },
  methods: {
  },
  filters: {
    numberFormat: function (number) {
      if (typeof number === 'string' && number.match(/[0-9\-]/)) {
        number = parseInt(number);
      }
        if (typeof number !== "number") {
            return number;
        }
        return number.toLocaleString();
    },
  },
});
